import * as React from "react";
import { graphql, HeadFC } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import { PortableText } from "@portabletext/react";

const Admin = ({ data, pageContext }: { data: any; pageContext: any }) => {
  const Author = data.sanityAuthor;
  return (
    <main>
      <Layout></Layout>
      <article className="h-screen flex flex-col justify-center items-center gap-4 rounded-xl p-10 transition-all duration-500 group-hover:bg-richblack">
        <GatsbyImage
          image={Author.image.asset.gatsbyImageData}
          alt={Author.image.alt}
          className="w-40 rounded-full"
        ></GatsbyImage>
        <h1 className="text-4xl text-richblack font-extrabold transition-all duration-500 group-hover:text-platinum underline">
          {Author.name}
        </h1>
        <h2 className="text-shadowblue transition-all duration-500 group-hover:text-bdazzledblue">
          {Author.position}
        </h2>
        <span
          className="[&>p]:px-36 [&>p]:text-justify [&>p]:text-richblack [&>p]:font-medium [&>p]:tracking-wide [&>p]:text-2xl
              [&>p]:transition-all [&>p]:duration-500 [&>p]:group-hover:text-platinum"
        >
          <PortableText value={Author._rawBio}></PortableText>
        </span>
      </article>
    </main>
  );
};

export const query = graphql`
  query ($id: String) {
    sanityAuthor(_id: { eq: $id }) {
      name
      position
      _rawBio
      image {
        asset {
          gatsbyImageData
        }
        alt
      }
    }
  }
`;

export const Head: HeadFC = ({ pageContext }: { pageContext: any }) => (
  <title>{pageContext.currentAdmin}</title>
);

export default Admin;
